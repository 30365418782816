<template>
  <div class="page1">
    <homeHeader></homeHeader>
    <div class="newsInfoDetailBox">
      <newBreadCrumb></newBreadCrumb>
      <h1>{{ title }}</h1>
      <p>{{time || ''}}</p>
      <!-- 富文本样式 -->
       <div class="richText pc" v-html="setContent1"></div>
       <div class="richText h5" v-html="setContent"></div>
    </div>
    <homeFooter></homeFooter>
  </div>
</template>
<script>
import homeHeader from '../../components/homeHeader.vue'
import homeFooter from '../../components/homeFooter.vue'
import newBreadCrumb from '../../components/newBreadCrumb.vue'
import DOMPurify from 'dompurify';

import { getNewsItemDetail } from '@/api/public.js'
export default {
  name: '',
  props: {},
  components: { homeHeader,homeFooter,newBreadCrumb },
  data() {
    return {
      time: '',
      content: '',
      code: '',
      title: ''
    }
  },
  filters: {
   
  },
  computed: {
    sanitizedHtml() {
      return DOMPurify.sanitize(this.content);
    },
    setContent1() {
      let htmlString = this.content || ""
      // let newHtmlString = htmlString &&  htmlString.replace(/<img/g, '<img style="max-width:100%;max-height:100%;object-fit: cover;"') || ""
      let newHtmlString1 = htmlString &&  htmlString.replace(/<video/g, '<video style="max-width:100%;max-height:100%;object-fit: cover;display: block;margin: 0 auto;"') || ""
      return htmlString
    },
    setContent() {
      let htmlString = this.content || ""
      let newHtmlString = htmlString &&  htmlString.replace(/<img/g, '<img style="max-width:100%;max-height:100%;object-fit: cover;"') || ""
      let newHtmlString1 = newHtmlString &&  newHtmlString.replace(/<video/g, '<video style="max-width:100%;max-height:100%;object-fit: cover;display: block;margin: 0 auto;"') || ""
      console.log(newHtmlString1)
      return newHtmlString1
    },
  },
  created(){
  },
  methods: {
    getData() {
      getNewsItemDetail(this.code).then(res=>{
        console.log(res)
        this.content= res.data.content
        var time = res.data.createTime || ""
        this.title = res.data.title
        this.time = this.$wmtDate.fromatDate('yyyy-MM-dd', new Date(time))
      })
    }
  },
  mounted() {
    this.code = this.$route.query.code || ""
    if(this.code) {
      this.getData()
    }
  }
}
</script>

<style scoped>

</style>
<style scoped lang="less">
.page1 {
  min-height: 100vh;
  display: flex;
  flex-flow: column wrap;
  font-size: 16px;
}

.newsInfoDetailBox {
  width: 100%;
  padding: 0 12em;
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 1em;
  margin-bottom:1em;
  img {
    width: 100%;
    height: 100%;
  }
  h1 {
    text-align: center;
    // margin-top: 2.75em;
    margin-top: 2em;
    color: #333333;
    font-size: 1.625em;
    font-weight: bold;
    letter-spacing: .125em;
  }
  p {
    text-align: center;
    margin-top: .625em;
    color: #a1a1a1;
    font-size: 1.25em;
    font-weight: bold;
  }

  .richText {
    font-size: 1em;
    color: #262626;
    &.pc {
      display: block;
      /deep/ img {
        max-width: 100%;
        min-height: 100%;
      }
    }
    &.h5 {
      display: none;
    }
  }
}
 // 移动端开始
@media screen and (max-width: 768px) {
 .newsInfoDetailBox {
  padding: 0 12px;
  
  h1 {
    margin-top: 24px;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 0 40px;
  }
  p {
    margin-top: 8px;
    font-size: 12px;
  }
  .richText {
    font-size: 14px !important;
    color: #262626;
    &.pc {
      display: none;
    }
    &.h5 {
      display: block;
      /deep/ span {
        font-size: 15px !important;
      }
    }
  }
 }
}
 // 移动端结束

//   平板开始
@media screen and (min-width: 768px) and (max-width: 992px) {
  .page1 {
    font-size: 14px;
    .newsInfoDetailBox {
      padding: 0 3em;
    }
  }
}

// 平板结束

// 中等屏幕开始

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .page1 {
    font-size: 18px;
    .newsInfoDetailBox {
      padding: 0 3em;
      margin-bottom:1em;
    }
  }
}

// 中等屏幕结束

// 大屏幕1200px - 1596px 开始
@media screen and (min-width: 1200px) and (max-width: 1596px) {
  .page1 {
    font-size: 20px;
    .newsInfoDetailBox {
      padding: 0 3em;
      margin-bottom:1em;
    }
  }
}
// 大屏幕1200px - 1596px 结束


// 大屏幕1596px 开始
@media screen and (min-width: 1596px) {
  .page1 {
    font-size: 24px;
  }
}
// 大屏幕1596px 结束


</style>